import React from "react"

// Stylesheet
import styles from "./index.module.scss"

// Images
import Ad from "../../images/inner-ads.jpg"
import AdMobile from "../../images/ads-shadow.jpg"

const AdSection = () => {
  return (
    <div className={styles.adSection}>
      <div className="max-w-6xl mx-auto px-4 xl:px-0">
        <img src={Ad} alt="Ads" className="hidden md:block w-full" />
        <img src={AdMobile} alt="Ads" className="md:hidden w-full" />
      </div>
    </div>
  )
}

export default AdSection
