import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import { Banner, Content } from "../containers/common"
import AdSection from "../components/adSection"

const ContentPage = ({ data }) => {
  const { ogtitle, ogdescription, title, content } = data.content

  return (
    <Layout>
      <SEO title={ogtitle} description={ogdescription} />
      <Banner title={title} />
      <Content data={content.raw} />
      {/* <AdSection /> */}
    </Layout>
  )
}

export const query = graphql`
  query ContentPageQuery($slug: String) {
    content: contentfulContentPage(slug: { eq: $slug }) {
      ogtitle
      ogdescription
      title
      content {
        raw
      }
    }
  }
`

export default ContentPage
