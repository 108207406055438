import React from "react"

// Styles
import styles from "./index.module.scss"

// Images
import stroke from "../../../images/stroke2.svg"

const Banner = ({ title, description }) => {
  return (
    <div id="banner" className={styles.banner}>
      <div className={`${styles.container} max-w-6xl mx-auto px-4 xl:px-0`}>
        <h1 className={styles.title}>{title}</h1>
        {description && (
          <p
            className={`${styles.description} w-full md:w-3/5 md:mx-auto mt-6`}
          >
            {description}
          </p>
        )}
      </div>
      <img src={stroke} alt="horizontal stroke" className={styles.stroke} />
    </div>
  )
}

export default Banner
