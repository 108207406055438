import React from "react"

// Components
import RichTextRenderer from "../../../components/richTextRenderer"

const Content = ({ data }) => {
  return (
    <div className="max-w-3xl mx-auto px-4 xl:px-0 py-16">
      <RichTextRenderer content={data} />
    </div>
  )
}

export default Content
